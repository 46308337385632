<template>
  <div
    class="sky__product-card relative"
    :class="cartIconIsHovered && product.pricing && product.pricing.decreasing_prices && product.pricing.decreasing_prices.length ? 'is-hovered' : ''"
    @mouseleave="cartIconIsHovered = false"
    @click="$emit('click')"
  >
    <nuxt-link
      :style="{
        paddingTop: product.cover ? '' : '100%',
        backgroundSize: 'cover',
        backgroundImage: product.properties.background ? `url(${product.properties.background}?resize=true&width=300&height=300&format=webp)` : undefined
      }"
      class="bg-sky_grey_darker mb-3 relative cursor-pointer block"
      :to="$helpers.makeUri(product.path)"
    >
      <img
        v-if="product.cover"
        class="sky__product-card-cover"
        :class="['sky__product-card-cover-animation_' + product.properties.effets, animated ? 'animated' : '']"
        :src="$helpers.makeCdnUrl(product.cover, product.slug, 'c', 'pc')"
        :alt="product.label"
        loading="lazy"
        width="300"
        height="300"
        @mouseenter="startAnimation()"
      >
      <div v-if="discountPercentage" class="absolute font-funny bg-sky_orange text-white text-xl rounded-full px-2 py-2" style="bottom: 10px; right: 10px;">
        -{{ discountPercentage }}%
      </div>
    </nuxt-link>
    <div class="px-2">
      <div class="sky__product-name">
        <nuxt-link :to="$helpers.makeUri(product.path)">
          {{ product.label }}
        </nuxt-link>
      </div>
      <div
        class="flex mb-3 items-center justify-between"
        style="height: 16px;"
      >
        <div
          v-if="product.properties.reviews"
          class="flex items-center"
          :style="{
            opacity: product.properties.reviews.total === 0 ? 0 : 1
          }"
        >
          <notation
            :notation="product.properties.reviews.avg"
            :max="5"
          />
          <span class="ml-1 text-xxs">
            ({{ product.properties.reviews.total }})
          </span>
        </div>
        <div v-if="product.pricing.pcb > 1" class="text-xs">
          PCB: {{ product.pricing.pcb }}
        </div>
      </div>
      <div
        v-if="product.pricing"
        class="flex mb-3 items-center justify-between"
      >
        <div
          v-if="product.reviewSummary"
          class="flex items-center"
          :style="{
            opacity: product.reviewSummary.total === 0 ? 0 : 1
          }"
        >
          <span class="ml-1 text-xxs">
            ({{ product.reviewSummary.total }})
          </span>
        </div>
      </div>
      <div v-if="product.pricing" class="flex justify-between items-center">
        <div class="flex items-center">
          <div
            class="text-lg font-bold"
            :class="{
              'text-sky_secondary': product.pricing.unit_taxed_price !== product.pricing.crossed_taxed_price
            }"
          >
            {{ $helpers.formatCurrency($store.getters['auth/paysTaxes'] ? product.pricing.unit_taxed_price : product.pricing.unit_price) }}
            <small v-if="!$store.getters['auth/paysTaxes']">HT</small>
          </div>
          <div v-if="product.pricing.unit_taxed_price !== product.pricing.crossed_taxed_price" class="ml-2 text-xs hover:no-underline line-through text-gray-400">
            {{ $helpers.formatCurrency(product.pricing.unit_taxed_price ? product.pricing.crossed_taxed_price : product.pricing.crossed_price) }}
            <small v-if="!$store.getters['auth/paysTaxes']">HT</small>
          </div>
        </div>
        <div
          v-if="product.properties.stock_status === 'available'"
          class="sky__product-card-add-product-btn"
          @mouseenter="() => {
            cartIconIsHovered = true
          }"
          @click="addPcbToCart"
        >
          <div v-if="!justAddedToCart">
            <paper-plane class="paper-plane text-sky_secondary" style="width: 35px;" />
            <icon-cart-add class="cart-icon" style="width: 38px;" />
          </div>
          <transition name="cart-icon-transition">
            <div v-if="justAddedToCart" class="relative paper-plane-icon-animated" style="width: 35px;">
              <paper-plane class="text-sky_secondary" style="width: 35px;" />
            </div>
          </transition>
        </div>
        <div v-else-if="product.properties.stock_status === 'available_later'" class="text-sky_orange text-sm flex items-center text-xs" style="height: 38px;">
          <nuxt-link :to="$helpers.makeUri(product.path)">
            Commander
          </nuxt-link>
        </div>
        <div v-else-if="product.properties.stock_status === 'out_of_stock'" class="text-red-500 text-sm flex items-center text-xs" style="height: 38px;">
          Rupture
        </div>
        <div v-show="cartIconIsHovered" v-if="product.pricing.decreasing_prices && product.pricing.decreasing_prices.length" class="sky__product-card-decreasing-prices">
          <ul>
            <li
              v-for="dp in product.pricing.decreasing_prices"
              :key="dp.minimal_quantity"
            >
              <div class="sky__decreasing-price cursor-pointer" @click.prevent.stop="addToCart(dp.minimal_quantity, 'Petit Panier - Centre - x' + dp.minimal_quantity)">
                <div>
                  x{{ dp.minimal_quantity }}:
                  <span class="ml-5">
                    {{ $helpers.formatCurrency($store.getters['auth/paysTaxes'] ? dp.unit_taxed_price : dp.unit_price) }}
                    <small v-if="!$store.getters['auth/paysTaxes']">HT</small>
                  </span>
                </div>
                <div class="cursor-pointer">
                  <div
                    v-if="addingToCart !== dp.minimal_quantity"
                    @click.stop="addToCart(dp.minimal_quantity, 'Petit Panier - x' + dp.minimal_quantity)"
                  >
                    <icon-cart-add style="width: 25px;" />
                  </div>
                  <template v-else>
                    <pulse-loader size="5px" loading color="white" />
                  </template>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div
        v-if="headband"
        class="absolute font-funny text-xl px-3 pb-0 pt-1 font-thin"
        :style="{
          top: '5px',
          left: '0px',
          backgroundColor: headband.color,
          color: 'white'
        }"
      >
        {{ headband.text }}
      </div>
      <nuxt-link v-if="product.properties.stock_status === 'out_of_stock'" :to="$helpers.makeUri(product.path)" class="block absolute left-0 top-0 right-0" style="background: white; opacity: 0.7; bottom: 50px;" />
      <div
        class="absolute px-3 pb-0 pt-1"
        :style="{
          right: '5px',
          top: '5px'
        }"
      >
        <wishlist-button
          :sku="product.sku"
          :cover="product.cover"
          :label="product.label"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import WishlistButton from '../reusable/WishlistButton'
import PaperPlane from './icons/PaperPlane'
import Notation from './Notation'

const playAudio = (path) => {
  try {
    new Audio(path).play()
  } catch (error) {
    // Do nothing..;
  }
}

export default {
  components: {
    PulseLoader,
    PaperPlane,
    Notation,
    WishlistButton
  },
  props: {
    product: {
      required: false,
      type: Object,
      default: null
    }
  },
  data () {
    return {
      cartIconIsHovered: false,
      cartIconIsReallyHovered: false,
      addingToCart: null,
      animated: false,
      justAddedToCart: false
    }
  },
  computed: {
    headband () {
      return this.product.properties.headband
    },
    discountPercentage () {
      if (!this.product.pricing) {
        return null
      }
      const percentage = (this.product.pricing.crossed_taxed_price - this.product.pricing.unit_taxed_price) / this.product.pricing.crossed_taxed_price * 100
      return Math.ceil(percentage) || null
    }
  },
  methods: {
    async addToCart (quantity, origin) {
      this.addingToCart = quantity
      this.justAddedToCart = true
      setTimeout(() => {
        this.justAddedToCart = false
      }, 1000)
      try {
        const { price } = await this.$store.dispatch('cart/addProduct', {
          sku: this.product.sku,
          label: this.product.label,
          cover: this.product.cover,
          quantity,
          pricing: this.product.pricing,
          properties: {
            path: this.product.path,
            uri: this.$helpers.makeUri(this.product.path),
            background: this.product.properties.background,
            crossed_price: this.$store.getters['auth/paysTaxes'] ? this.product.pricing.crossed_taxed_price : this.product.pricing.crossed_price
          }
        })
        this.$gtmEvents.onAddedToCart(this.product, quantity, price, origin)
        this.$store.commit('cart/setShow', true)
        playAudio(require('../../assets/sounds/add-to-cart.mp3'))
      } catch (error) {
        this.$gtmEvents.onRuntimeError(error, 'product-card/add-to-cart')
      }
      this.addingToCart = null
    },
    startAnimation () {
      if (this.animated) {
        return false
      }
      this.animated = true

      setTimeout(() => {
        this.animated = false
      }, 5000)
    },
    async addPcbToCart () {
      if (this.justAddedToCart) {
        return false
      }

      await this.addToCart(this.product.pricing.pcb, 'Petit Panier - Main')
    }
  }
}
</script>

<style lang="scss" scoped>
.sky__product-card {
  @apply pb-3 shadow-none;
  transition-duration: 0.2s;
  min-width: 155px;
  &.is-hovered {
    @apply shadow-md;
  }
  &:hover {
    @apply shadow-md;
  }
  .sky__product-card-cover {
    @apply block w-full;
    &.sky__product-card-cover-animation_vertical_illumination {
      transform: translateY(8px);
      &.animated {
        animation: wobble-vertical 5s infinite;
        animation-timing-function: ease-in-out;
        filter: drop-shadow(0px 0px 8px lightyellow);
      }
    }
    &.sky__product-card-cover-animation_illumination,&.sky__product-card-cover-animation_pendule_illumination {
      &.animated {
        filter: drop-shadow(0px 0px 8px lightyellow);
      }
    }
    &.sky__product-card-cover-animation_vertical {
      transform: translateY(8px);
      &.animated {
        animation: wobble-vertical 5s infinite;
        animation-timing-function: ease-in-out;
      }
    }
    &.sky__product-card-cover-animation_pendule,&.sky__product-card-cover-animation_pendule_illumination{
      &.animated {
        margin:0 auto;
        display:block;
        animation: pendulum;
        animation-iteration-count: 1;
        animation-duration: 5s;
        transform-origin: top center;
      }
    }
    &.sky__product-card-cover-animation_maracasse {
      &.animated {
        margin:0 auto;
        display:block;
        animation: chikachika;
        animation-iteration-count: 1;
        animation-duration: 1.2s;
        transform-origin: center center;
      }
    }
    &.sky__product-card-cover-animation_horizontal {
      transform: translateZ(0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      &.animated {
        animation-name: wobble-horizontal;
        animation-duration: 5s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 1;
      }
    }
  }

  .sky__product-card-add-product-btn {
    @apply cursor-pointer;
    width: 38px;
    height: 38px;
    .paper-plane {
      @apply hidden;
    }
    &:hover {
      .cart-icon {
        @apply hidden;
      }
      .paper-plane-icon {
        @apply block;
      }
    }
  }
}
.sky__product-name {
  @apply font-medium leading-snug mb-2 text-xs overflow-hidden;
  height: 45px;
  @screen md {
    @apply text-sm;
  }
  @screen lg {
    height: 40px;
    font-size:14px;
  }
}
.text-special-lg {
  @screen lg {
    font-size:14px;
  }
}
.sky__product-card-decreasing-prices {
  @apply hidden;
  @screen lg {
    @apply absolute block left-0 right-0 bg-white z-10 shadow-lg;
    top: 100%;
  }
  .sky__decreasing-price {
    @apply text-sm py-1 px-2 flex justify-between items-center;
    &:nth-child(odd) {
      @apply bg-sky_grey_darker;
    }
    &:nth-child(odd) {
      @apply bg-sky_grey_darker;
    }
    &:hover {
      @apply bg-sky_blue text-white;
      path {
        stroke: white;
        fill: white;
      }
    }
  }
}

.paper-plane-icon-animated {
  @apply relative z-50;
  transform: translate3D(800px, -800px, 0);
}
.cart-icon-transition-enter-active {
  animation: 1s cart-icon-animation;
  // animation-timing-function: ease-out;
}

.cart-icon-transition-enter {
  // transform: translateX(0);
}

@keyframes chikachika {
  from { transform: rotate(0deg); }
  1%  { transform: rotate(-8deg); }
  5%  { transform: rotate(8deg); }
  10%  { transform: rotate(-6deg); }
  15%  { transform: rotate(5deg); }
  20%  { transform: rotate(-4deg); }
  25%  { transform: rotate(3deg); }
  30%  { transform: rotate(0deg); }
  to   { transform: rotate(0deg); }
}

@keyframes cart-icon-animation {
  0% {
    transform: translate3D(0, 0, 0);
  }
  20% {
    transform: translate3D(-20px, 20px, 0);
    opacity: 1;
  }
  100% {
    transform: translate3D(800px, -800px, 0);
    opacity: 0.2;
  }
}

@keyframes wobble-vertical {
  16.65% {
    transform: translateY(16px);
  }

  33.3% {
    transform: translateY(2px);
  }

  49.95% {
    transform: translateY(12px);
  }

  66.6% {
    transform: translateY(6px);
  }

  83.25% {
    transform: translateY(9px);
  }

  100% {
    transform: translateY(8px);
  }
}

@keyframes wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }

  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }

  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }

  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }

  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes pendulum {
  from { transform: rotate(0deg); }
  12%  { transform: rotate(-9deg); }
  27%  { transform: rotate(8deg); }
  39%  { transform: rotate(-6deg); }
  52%  { transform: rotate(4deg); }
  62%  { transform: rotate(-3deg); }
  73%  { transform: rotate(2deg); }
  80%  { transform: rotate(-1deg); }
  88%  { transform: rotate(1deg); }
  94%  { transform: rotate(-0.5deg); }
  98%  { transform: rotate(0.5deg); }
  to   { transform: rotate(0deg); }
}
</style>
