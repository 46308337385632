<template>
  <div>
    <img
      v-if="(!isSelected || deleting) && !adding"
      src="../../assets/icons/interface-wishlist-empty-dark.svg?img"
      style="width: 28px;"
      alt="Wishlist Icon"
      class="cursor-pointer"
      @click.stop.prevent="saveToWishlist"
    >
    <transition name="wishlist-transition">
      <img
        v-if="(isSelected && !deleting) || adding"
        src="../../assets/icons/interface-wishlist.svg?img"
        style="width: 28px;"
        alt="Wishlist Icon"
        class="cursor-pointer"
        @click.stop.prevent="deleteFromWishlist"
      >
    </transition>
  </div>
</template>

<script>

export default {
  props: {
    sku: {
      required: true,
      type: String
    },
    cover: {
      required: false,
      type: String,
      default: null
    },
    label: {
      required: false,
      type: String,
      default: null
    }
  },
  data () {
    return {
      adding: false,
      deleting: false,
      showLoginDialog: false
    }
  },
  computed: {
    isSelected () {
      return this.$store.state.cart.wishlists.find(w => w.products.find(p => p.sku === this.sku))
    }
  },
  methods: {
    async saveToWishlist () {
      if (!this.$store.getters['auth/isLoggedIn']) {
        await this.$router.push(`/auth?redirect_to=${this.$route.fullPath}`)
        return
      }

      if (this.adding || this.deleting) {
        return
      }

      this.adding = true
      try {
        await this.$store.dispatch('cart/fetchWishlists')
        if (!this.$store.state.cart.wishlists.length) {
          await this.$api.post('/wishlists', {
            name: 'Ma Wishlist'
          })
          await this.$store.dispatch('cart/fetchWishlists')
        }
        const defaultWishlist = this.$store.state.cart.wishlists.find(w => w.is_favorite) || this.$store.state.cart.wishlists[0]
        await this.$api.post(`/wishlists/${defaultWishlist.id}/products`, {
          sku: this.sku,
          cover: this.cover,
          label: this.label
        })
        await this.$store.dispatch('cart/fetchWishlists')
      } catch (error) {
        this.$gtmEvents.onRuntimeError(error, 'wishlists/add-to-wishlist')
      }

      this.adding = false
    },
    async deleteFromWishlist () {
      if (this.adding || this.deleting) {
        return
      }
      const current = this.isSelected
      if (!current) {
        return
      }
      this.deleting = true
      try {
        await this.$api.delete(`/wishlists/${current.id}/products/${this.sku}`)
        await this.$store.dispatch('cart/fetchWishlists')
      } catch (error) {
        this.$gtmEvents.onRuntimeError(error, 'wishlists/delete-from-wishlist')
      }

      this.deleting = false
    }
  }
}
</script>

<style lang="scss" scoped>
.wishlist-transition-enter-active {
  animation: wishlist-enter 0.2s;
}
.wishlist-transition-leave-active {
  display: none;
}

.sky__wishlist-login-dialog {
  @screen md {
    width: 800px;
  }
}

@keyframes wishlist-enter {
  0% {
    transform: scale(1.2);
  }
  80% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
</style>
