<template>
  <paper-plane-icon
    class="paper-plane-icon"
  />
</template>

<script>
import PaperPlaneIcon from '../../../assets/icons/paper-plane.svg'

export default {
  components: {
    PaperPlaneIcon
  }
}
</script>

<style lang="scss">
  .paper-plane-icon {
    path {
      fill: currentColor;
    }
  }
</style>
